import anime from 'animejs/lib/anime.es.js';

let letSpNavBtn, slideNavAnimeOpen, slideNavAnimeClose, Scrollpos;

export function globalNavigationFunc() {
  letSpNavBtn = $('#spNavBtn');
  //閉じる
  function navClose() {
    anime({
      targets: '#globalNavigations',
      translateX: {
        value: ['0', '-100%'],
        duration: 800,
        easing: 'easeInQuint',
      },
      direction: 'normal',
      complete: function () {
        $('#globalNavigations').removeAttr('style').find('ul li').removeAttr('style');
      }
    });
  }
  //開ける
  function navOpen() {
    slideNavAnimeOpen = anime({
      targets: '#globalNavigations',
      translateX: {
        value: ['-100%', '0'], // []で開始値と終了値を指定できます
        duration: 600, // 0.8秒間アニメーションする
        easing: 'easeInQuint',
      },
      direction: 'normal',
      complete: function () {
        anime({
          targets: '#globalNavigations ul li',
          //delay:300,
          opacity: {
            value: [0, 1],
            duration: 800,
            easing: 'easeOutQuart',
          },
          translateY: {
            value: ['3vh', '0'],
            duration: 1000,
            easing: 'easeOutQuart',
          },
          direction: 'normal',
          delay: function (el, i, l) {
            return i * 100;
          }
        });
      },
    });
  }

  letSpNavBtn.on('click', function () {
    $(this).stop().toggleClass('open');
    if ($(this).hasClass('open')) {
      navOpen();
      Scrollpos = $(window).scrollTop();
      $('body').addClass('fixedBody').css({
        'top': -Scrollpos
      });
    } else {
      navClose();
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, Scrollpos);
    }
  });
}

export function buttonActionFunc() {
  let letPrimeglobal;
  letPrimeglobal = $('.alertPop');
  letPrimeglobal.on('click', function () {
    if (window.confirm("ここから先は、ASAグループのホームページではありません。\n訪問先で生じる問題についてASAグループは責任を負いません。\n\nYou are now leaving the ASA Group. web site. Neither ASA Group. nor PrimeGlobal are responsible for the content of the site you are visiting.")) {
      window.open("https://primeglobal.net/");
    } else {
      return false;
    }
  });
  
  let fiscalYearDt;
  fiscalYearDt = $('.jsDtWp');
  fiscalYearDt.on('click', function () {
    $(this).toggleClass('open').next('dd').slideToggle(500);    
  });
  
}

export function careerToggleFunc() {
  let careerToggleBtn, careerToggleList;
  careerToggleBtn = $('.careerToggleBtn');
  careerToggleList = $('.careerToggle');

  careerToggleBtn.on('click', function () {
    $(this).toggleClass('open');
    $(this).parent('.memberDetail').find(careerToggleList).slideToggle(500);
  });
}

/*サブナビゲーション*/
export function eachPageSubNavigationFunc() {
  let letSpSetTxt, letJsEachNav;
  letSpSetTxt = $('#spSetTxt');
  letJsEachNav = $('#jsEachNav').find('a.currentOn').text();
  letSpSetTxt.text(letJsEachNav);

  letSpSetTxt.on('click', function () {
    $(this).toggleClass('open');
    $(this).next().stop().slideToggle(500);
  });
}

/*サービス下層ページ サイドナビゲーション*/
export function sideNavigationFunc() {
  let letsideNavTxt, letJsSideNav;
  letsideNavTxt = $('#sideNavTxt');
  letJsSideNav = $('#jsSideNav').find('a.currentOn').text();
  letsideNavTxt.text(letJsSideNav);

  letsideNavTxt.on('click', function () {
    $(this).toggleClass('open');
    $(this).next().stop().slideToggle(500);
  });
}

/*サービス下層ページ サイドナビゲーション*/
export function zoomInFunc() {
  let letZoomBtn, letImgBox, letModalImg, letHideContent, letZoomBtn02, letImgBox02, letModalImg02, letMapBtn;
  letZoomBtn = $('.zoomBtn');
  letImgBox = $('.imgBox');
  letModalImg = $('.modalImg');
  letHideContent = $('#hideContent');
  letMapBtn = $('.mapBtn');

  letZoomBtn.on('click', function () {
    $(this).parent(letImgBox).addClass('open');
    if (letImgBox.hasClass('open')) {
      letHideContent.addClass('cancell');
    }
  });
  
  letModalImg.on('click',function () {
    if(!$(event.target).closest('img , p').length) {
      $(this).parent(letImgBox).removeClass('open');
      letHideContent.removeClass('cancell');

      $(this).find('.mapImage').removeClass('hide');
      letMapBtn.parents('.attention').removeClass('hide');
      $(this).find('.mapScaleImage').addClass('hide');
      $(this).find('.backMapBtn').addClass('hide');
    }
  });

  letZoomBtn02 = $('.modalBtn');
  letImgBox02 = $('.imgModalBox');
  letModalImg02 = $('.modalImg02');

  letZoomBtn02.on('click', function () {
    $(this).parents(letImgBox02).addClass('open');
    if (letImgBox02.hasClass('open')) {
      letHideContent.addClass('cancell');
    }
  });
  
  letModalImg02.on('click',function () {
    if(!$(event.target).closest('img , p').length) {
      $(this).parents(letImgBox02).removeClass('open');
      letHideContent.removeClass('cancell');
    }
  });

  letMapBtn.on('click', function () {
    $(this).parents(letModalImg).find('.mapImage').addClass('hide');
    $(this).parents(letModalImg).find('.mapScaleImage').removeClass('hide');
    $(this).parents(letModalImg).find('.backMapBtn').removeClass('hide');
    $(this).parents('.attention').addClass('hide');
  });
  $('.backMapBtn').on('click', function () {
    $(this).parents(letModalImg).find('.mapImage').removeClass('hide');
    $(this).parents(letModalImg).find('.attention').removeClass('hide');
    $(this).parents(letModalImg).find('.mapScaleImage').addClass('hide');
    $(this).addClass('hide');
  });
}